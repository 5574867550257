<template>
    <div v-show="showPane" ref="measurePane" class="measure-pane">
        <div class="descText">
            Please use the following buttons to pick a measurement type and
            unit. Then click measure to begin measuring on the map.
        </div>
        <!-- row for the radiobuttons to change between tabs -->

        <label for="measureTypeSelect" class="label">Measurement Type:</label>
        <select
            id="measureTypeSelect"
            class="unitSelect"
            v-model="measureType"
            @change="presetMeasureType"
        >
            <option value="Area">Area</option>
            <option value="Distance">Distance</option>
            <option value="Location">Location</option></select
        >
        <label for="unitSelect" class="label">Units:</label>
        <select
            ref="unitSelect"
            class="unitSelect"
            @change="setMeasurementUnits"
            v-model="measureUnits"
        >
            <option
                v-for="(unit, index) in units[measureType.toLowerCase()]"
                :key="index"
                :value="unit"
            >
                {{ unit }}
            </option>
        </select>
        <!-- Measure Area tab -->
        {{ convertedMeasurement }}
        <br />
        <div class="d-flex justify-content-between pb-1 btn-row">
            <button
                class="btn btn-gray "
                v-bind:class="
                    clickFunction == 'measureArea' ? 'btn-blue' : 'btn-gray'
                "
                @click="startMeasure"
            >
                Measure
            </button>
            <button
                v-if="clickFunction != ''"
                class="btn btn-clear btn-buffer"
                @click="cancelMeasure"
            >
                Cancel
            </button>
            <button class="btn btn-clear btn-buffer" @click="clearMeasure">
                Clear
            </button>
        </div>
    </div>
</template>

<script>
import { getPathLength, getAreaOfPolygon } from 'geolib';
import { spatialFunctions } from '../../utilities/spatialFunctions.js';

/**
 * Add any custom component as a leaflet control
 */
export default {
    name: 'MeasurePane',
    components: {},
    mixins: [],
    props: {
        showPane: {
            type: Boolean,
            default: false,
            required: true,
        },
        midXPosition: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    data() {
        return {
            measureType: 'Area',
            measureShapeColor: 'orange',
            startPointRadius: 2,
            measurement: '',
            measureUnits: 'acres',
            width: 300,
            units: {
                location: ['Decimal Degrees', 'D° M\' S"'],
                distance: ['Meters', 'Feet', 'Kilometers', 'Miles'],
                area: [
                    'Acres',
                    'Hectares',
                    'Square Meters',
                    'Square Feet',
                    'Square Kilometers',
                    'Square Miles',
                ],
            },
        };
    },
    mounted() {
        window.addEventListener('resize', this.updateDrawPanePosition);
        this.$parent.$parent.$parent.$on('map/click', this.handleMouseClick);
        this.$parent.$parent.$parent.$on(
            'map/dblClick',
            this.handleMouseDblClick
        );
        this.$parent.$parent.$parent.$on('ready', this.measureReady);
        this.$parent.$parent.$parent.$emit('ready', this.mapObject);
        this.presetMeasureType();
    },
    render() {
        return null;
    },
    methods: {
        convertLocation(coordinates, target_unit) {
            const conversions = {
                'Decimal Degrees':
                    coordinates.lat.toFixed(6) +
                    ', ' +
                    coordinates.lng.toFixed(6),

                'D° M\' S"': this.dd2dms(coordinates.lat, coordinates.lng),
            };
            return conversions[target_unit];
        },
        convertDistance(distance_meters, target_unit) {
            const conversions = {
                Meters: 1,
                Feet: 3.28084,
                Kilometers: 0.001,
                Miles: 0.000621371,
            };
            var convertedDistance = distance_meters * conversions[target_unit];
            return convertedDistance.toFixed(6);
        },
        convertArea(area_meters, target_unit) {
            const conversions = {
                Acres: 0.000247105,
                Hectares: 0.0001,
                'Square Meters': 1,
                'Square Feet': 10.7639,
                'Square Kilometers': 0.000001,
                'Square Miles': 0.000000386102,
            };
            var convertedArea = area_meters * conversions[target_unit];
            return convertedArea.toFixed(6);
        },
        startMeasure() {
            this.clearBufferShapes();
            this.measurement = '';
            this.measureUnits = this.$refs.unitSelect.value;
            if (this.measureType == 'Location') {
                this.measureLocation();
            } else if (this.measureType == 'Distance') {
                this.measureDistance();
            } else if (this.measureType == 'Area') {
                this.measureArea();
            }
            this.$emit('updateClickFunction', 'measure' + this.measureType);
        },
        presetMeasureType() {
            this.measureUnits = this.units[this.measureType.toLowerCase()][0];
        },
        cancelMeasure() {
            this.$emit('updateClickFunction', '');
        },
        clearMeasure() {
            this.measurement = '';
            this.$parent.$parent.$parent.drawMarkers = this.$parent.$parent.$parent.drawMarkers.filter(
                (feature) => !feature.id.startsWith('MC')
            );
            this.$parent.$parent.$parent.drawLines = this.$parent.$parent.$parent.drawLines.filter(
                (feature) => !feature.id.startsWith('ML')
            );
            this.$parent.$parent.$parent.drawPolygons = this.$parent.$parent.$parent.drawPolygons.filter(
                (feature) => !feature.id.startsWith('MP')
            );
            this.$emit('updateClickFunction', '');
        },
        setMeasurementUnits(evt) {
            this.measureUnits = evt.target.value;
        },
        clearBufferShapes() {
            var i = this.$parent.$parent.$parent.drawMarkers.length;
            while (i--) {
                if (
                    this.$parent.$parent.$parent.drawMarkers
                        .at(i)
                        .id.startsWith('M')
                ) {
                    this.$parent.$parent.$parent.drawMarkers.splice(i, 1);
                }
            }
            i = this.$parent.$parent.$parent.drawLines.length;
            while (i--) {
                if (
                    this.$parent.$parent.$parent.drawLines
                        .at(i)
                        .id.startsWith('M')
                ) {
                    this.$parent.$parent.$parent.drawLines.splice(i, 1);
                }
            }
            i = this.$parent.$parent.$parent.drawPolygons.length;
            while (i--) {
                if (
                    this.$parent.$parent.$parent.drawPolygons
                        .at(i)
                        .id.startsWith('M')
                ) {
                    this.$parent.$parent.$parent.drawPolygons.splice(i, 1);
                }
            }
        },
        cleanupShapes() {
            var i = this.$parent.$parent.$parent.drawLines.length;
            while (i--) {
                if (
                    this.$parent.$parent.$parent.drawLines.at(i).geometry
                        .coordinates[0].length < 2
                ) {
                    this.$emit('updateClickFunction', '');
                }
            }
            i = this.$parent.$parent.$parent.drawPolygons.length;
            while (i--) {
                if (
                    this.$parent.$parent.$parent.drawPolygons.at(i).geometry
                        .coordinates[0].length < 3
                ) {
                    this.$parent.$parent.$parent.drawPolygons.splice(i, 1);
                }
            }
        }, // changes an array of arrays to an array of objects
        convertCoordinateStruct(coordinates) {
            let convertedCoordinates = [];
            coordinates.forEach((coordinate) => {
                convertedCoordinates.push({
                    latitude: coordinate[0],
                    longitude: coordinate[1],
                });
            });
            return convertedCoordinates;
        },
        handleMouseClick(event) {
            var [lat, lng] = spatialFunctions.validateCoordinates(event);
            let markerFeature = {
                // using letters and the count to create unique values between different object arrays
                //    since point 1 and line 1 would be overlapping and throw lots of warnings in the console
                //    but not errors that will cause issues in the actual execution/functionality
                // for this tool we use MC, ML, MP for Measure-Circle, Measure-Line, and Polygon
                //    this separates it from the drawtools C, L, P for Circle, Line Polygon
                id: 'MC',
                geometry: {
                    type: 'Point',
                    coordinates: [lat, lng],
                    coordSys: 'LatLong',
                },
                properties: {},
                styling: {
                    SymbologyColour: this.measureShapeColor,
                    SymbologySize: 3,
                    PolygonColor: this.measureShapeColor,
                    PolygonTransparency: 0,
                },
            };
            switch (this.clickFunction) {
                case 'measureLocation':
                    this.$parent.$parent.$parent.drawMarkers.push(
                        markerFeature
                    );
                    // only want one point at a time
                    this.$emit('updateClickFunction', '');
                    if (this.measureUnits == 'D° M\' S"') {
                        this.measurement = this.dd2dms(lat, lng);
                    } else {
                        var newCoord = '';
                        if (lat > 0) {
                            newCoord += lat.toFixed(6) + '°N ';
                        } else {
                            newCoord += Math.abs(lat.toFixed(6)) + '°S ';
                        }
                        if (lng > 0) {
                            newCoord += lng.toFixed(6) + '°E ';
                        } else {
                            newCoord += Math.abs(lng.toFixed(6)) + '°W ';
                        }
                        this.measurement = newCoord;
                    }
                    // this.$parent.$parent.$parent.mapOptions.doubleClickZoom=true
                    break;

                case 'measureDistance':
                    if (
                        this.$parent.$parent.$parent.drawLines.at(-1).geometry
                            .coordinates.length == 0
                    ) {
                        this.$parent.$parent.$parent.drawMarkers.push(
                            markerFeature
                        );
                    } else if (
                        this.$parent.$parent.$parent.drawLines.at(-1).geometry
                            .coordinates.length == 1
                    ) {
                        this.$parent.$parent.$parent.drawMarkers.pop();
                    }
                    this.$parent.$parent.$parent.drawLines
                        .at(-1)
                        .geometry.coordinates.push([lat, lng]);
                    this.measurement = getPathLength(
                        this.convertCoordinateStruct(
                            this.$parent.$parent.$parent.drawLines.at(-1)
                                .geometry.coordinates
                        )
                    );
                    break;

                case 'measureArea':
                    if (
                        this.$parent.$parent.$parent.drawPolygons.at(-1)
                            .geometry.coordinates[0].length == 0
                    ) {
                        this.$parent.$parent.$parent.drawMarkers.push(
                            markerFeature
                        );
                        this.$parent.$parent.$parent.drawPolygons
                            .at(-1)
                            .geometry.coordinates[0].push([lat, lng]);
                    } else if (
                        this.$parent.$parent.$parent.drawLines.at(-1).geometry
                            .coordinates.length == 1
                    ) {
                        this.$parent.$parent.$parent.drawMarkers.pop();
                    }
                    this.$parent.$parent.$parent.drawLines
                        .at(-1)
                        .geometry.coordinates.push([lat, lng]);
                    this.$parent.$parent.$parent.drawPolygons
                        .at(-1)
                        .geometry.coordinates[0].push([lat, lng]);
                    this.measurement = getAreaOfPolygon(
                        this.convertCoordinateStruct(
                            this.$parent.$parent.$parent.drawPolygons.at(-1)
                                .geometry.coordinates[0]
                        )
                    ).toFixed(2);
                    break;
            }
        },
        // disables the no-unused-vars error for the next line, want to keep event for future changes, not used now
        // eslint-disable-next-line no-unused-vars
        handleMouseDblClick(event) {
            switch (this.clickFunction) {
                case 'measureLocation':
                    break;
                case 'measureDistance':
                    this.$parent.$parent.$parent.drawLines
                        .at(-1)
                        .geometry.coordinates.pop();
                    break;

                case 'measureArea':
                    this.$parent.$parent.$parent.drawLines.pop();
                    this.$parent.$parent.$parent.drawPolygons
                        .at(-1)
                        .geometry.coordinates[0].pop();
                    this.$parent.$parent.$parent.drawPolygons.at(
                        -1
                    ).styling.PolygonTransparency = 0;
                    break;
            }
            this.$emit('updateClickFunction', '');
        },
        measureLocation() {
            this.cleanupShapes('point');
            this.$emit('updateClickFunction', 'measureLocation');
            this.measurement = '';
        },
        measureDistance() {
            this.cleanupShapes('line');
            this.$emit('updateClickFunction', 'measureDistance');
            this.$parent.$parent.$parent.drawLines.push({
                id: 'ML' + this.$parent.$parent.$parent.drawLines.length,
                geometry: {
                    coordinates: [],
                    type: 'LineString',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonBorderColour: this.measureShapeColor,
                },
            });
            this.measurement = '';
        },
        measureArea() {
            this.cleanupShapes('polygon');
            this.$emit('updateClickFunction', 'measureArea');
            this.$parent.$parent.$parent.drawLines.push({
                id: 'ML' + this.$parent.$parent.$parent.drawLines.length,
                geometry: {
                    coordinates: [],
                    type: 'LineString',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonBorderColour: this.measureShapeColor,
                },
            });
            this.$parent.$parent.$parent.drawPolygons.push({
                id: 'MP' + this.$parent.$parent.$parent.drawPolygons.length,
                geometry: {
                    coordinates: [[]],
                    type: 'Polygon',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonColor: this.measureShapeColor,
                    PolygonBorderColour: this.measureShapeColor,
                    PolygonTransparency: 0.8,
                },
            });
            this.measurement = '';
        },
        toDMS(coordinate) {
            var absolute = Math.abs(coordinate);
            var degrees = Math.floor(absolute);
            var minutesNotTruncated = (absolute - degrees) * 60;
            var minutes = Math.floor(minutesNotTruncated);
            var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

            return degrees + '° ' + minutes + "' " + seconds + '"';
        },
        dd2dms(lat, lng) {
            var newCoord = '';
            if (lat > 0) {
                newCoord += this.toDMS(lat) + 'N ';
            } else {
                newCoord += this.toDMS(Math.abs(lat)) + 'S ';
            }
            if (lng > 0) {
                newCoord += this.toDMS(lng) + 'E ';
            } else {
                newCoord += this.toDMS(Math.abs(lng)) + 'W ';
            }
            return newCoord;
        },
        measureReady() {},
    },
    computed: {
        clickFunction() {
            return this.$parent.$parent.$parent.clickFunction;
        },
        convertedMeasurement: function() {
            if (this.measurement == '' || parseFloat(this.measurement) == 0) {
                return '';
            } else if (this.measureType == 'Location') {
                return this.convertLocation(
                    this.measurement,
                    this.measureUnits
                );
            } else if (this.measureType == 'Distance') {
                return this.convertDistance(
                    parseFloat(this.measurement),
                    this.measureUnits
                );
            } else if (this.measureType == 'Area') {
                return this.convertArea(
                    parseFloat(this.measurement),
                    this.measureUnits
                );
            }
            return '';
        },
    },
    watch: {
        clickFunction(newVal, oldVal) {},
    },
};
</script>

<style scoped>
.buttonContainer {
    padding: 20px;
    background-color: white;
    border: solid 1px darkgray;
}
.radioButton {
    display: inline-block;
}
#radioRow {
    padding-bottom: 7px;
    display: flex;
    justify-content: space-around;
}
.unitSelect {
    width: 260px;
    height: 36px;
    border-radius: 4px;
}
.distanceEntry {
    width: 60px;
}
.label {
    margin-left: 5px;
}
.btn-gray {
    background-color: gray !important;
    color: white;
    height: 42px;
    width: 100px;
}
.btn-blue {
    background-color: rgb(77, 194, 233) !important;
}
.btn-clear {
    border: 1px solid gray;
}
.descText {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}
.measure-pane {
    width: 90%;
    padding: 25px;
}

.btn-row {
    padding-right: 16px;
}
</style>
